<template>
  <v-tooltip :right="right" :left="left" :bottom="bottom" :top="top" :max-width="maxWidth" :color="background">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="colour" :size="size" v-bind="attrs" v-on="on" :class="css">{{ icon }}</v-icon>
    </template>
    <slot class="tooltipCss">UofT Index</slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    icon: { type: String, default: 'mdi-information' },
    size: { type: Number, default: 24 },
    colour: { type: String, default: 'accent' },
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    bottom: { type: Boolean, default: true },
    top: { type: Boolean, default: false },
    css: { type: String, default: '' },
    tooltip: { type: Boolean, default: false },
    background: { type: String, default: 'tooltip' },
    maxWidth: { type: Number, default: 250 }
  }
}
</script>

<style scoped>
  .tooltipCss {
    z-index: 100;
    font-weight: 500;
  }

</style>
