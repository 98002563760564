<template>
  <v-row class="px-0 my-0" justify="center">
    <v-col class="banner" :style="bannerWidth">
      <div class="d-flex flex-row align-baseline">
        <p class="pageHeading headingSize mr-3 pb-0">{{ heading }}</p>
        <v-spacer v-if="banner"/>
        <slot/>
      </div>
      <p class="text-body-2 text--secondary font-weight-medium mb-0 px-1 pb-0">{{ subHeading }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    heading: { type: String, required: true },
    subHeading: { type: String, default: '' },
    banner: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
    maxWidth: { type: String, default: '1280px' }
  },
  computed: {
    bannerWidth () { return this.fullscreen ? '' : 'max-width: ' + this.maxWidth }
  }
}
</script>

<style scoped>
  .banner {
    padding: 5px 0;
  }

</style>
