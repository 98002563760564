<template>
  <v-menu transition="slide-y-transition" :offset-y="true" bottom close-on-click nudge-bottom="10" left>
    <template v-slot:activator="{ on }">
      <v-icon v-if="!large" v-on="on" size="20" class="mobileMenu">{{ icon }}</v-icon>
      <v-btn v-else v-on="on" color="transparent" depressed class="px-2 text-capitalize">
        <v-icon color="accent" class="mr-2">mdi-playlist-plus</v-icon>Save
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="!alreadyTaken" @click="toggleCourse('addCourse', course.code, true)">
        <v-list-item-content>
          <h4 class="align-self-center font-weight-regular text-body-2 text-no-wrap">
            <v-icon color="success" size="20" class="mr-2">mdi-check-decagram</v-icon>Already Taken</h4>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="saved" @click="toggleCourse('removeCourse', course.code, false)">
        <v-list-item-content>
          <h4 class="align-self-center font-weight-regular text-body-2 text-no-wrap">
            <v-icon color="error" size="20" class="mr-2">mdi-cancel</v-icon>Remove Course</h4>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!saved && !alreadyTaken" @click="toggleCourse('addCourse', course.code, false)">
        <v-list-item-content>
          <h4 class="align-self-center font-weight-regular text-body-2 text-no-wrap">
            <v-icon color="error" size="20" class="mr-2">mdi-bookmark</v-icon>Save for Later</h4>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'CourseToggle',
  props: {
    large: { type: Boolean, default: false },
    course: { type: Object, required: true },
    saved: { type: Boolean, required: true },
    alreadyTaken: { type: Boolean, required: true },
    icon: { type: String, default: 'mdi-dots-vertical' }
  },
  methods: {
    async toggleCourse (action, course, taken) {
      const queryObj = {
        query: `mutation ${action} ($course: ${action[0] === 'a' ? '[JSON]' : 'String'}!) { ${action} (course: $course) { status, message } }`,
        variables: { course: action[0] === 'a' ? [{ code: course, taken: taken }] : course },
        operationName: action
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(queryObj)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.data[action].status) {
              this.$toast.info(graphQlRes.data[action].message)
            } else {
              this.$toast.warning(graphQlRes.data[action].message)
            }
            this.$emit('courseToggle', graphQlRes.data[action].status)
          } else {
            this.$toast.error('An error occurred when contacting the server. Please try again later.')
          }
        }).catch(() => { this.$toast.error('An error occurred when contacting the server. Please try again later.') })
    }
  }
}
</script>

<style scoped>
  .mobileMenu:focus::after {
    opacity: 0 !important;
  }

</style>
